.couponsLink {
    font-family: 'Josefin Slab', serif;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 20px;
}

@media screen and (min-width: 20px) {
  .couponsLink {
    font-size: 14pt;
    padding-bottom: 24px;
    padding-top: 20px;
  }
}

@media screen and (min-width: 560px) {
  .couponsLink {
    font-size: 17pt;
    padding-bottom: 18px;
    padding-top: 30px;
  }
}

@media screen and (min-width: 768px) {
  .couponsLink {
    font-size: 23pt;
    padding-bottom: 24px;
    padding-top: 36px;
  }
}
